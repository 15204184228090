<template>
  <div>
    <VueECharts
      :options="chartOptions"
      style="width: 100%; height: 300px"
      @click="click_chart"
    />
    <mylist
      :isTest="isTest"
      :testtype="testtype"
      :items="items"
      :title="title_list"
      :headers="header_list"
      :dialog="dialog"
      @close_dialog="close_dialog"
      :key="lkey"
      :fluids="fluids"
      :fluids_elements="fluids_elements"
    ></mylist>
  </div>
</template>

<script>
import TestList from "../graphql/Stat/WELL_STAT_TEST_LIST.gql";
import FailureList from "../graphql/Stat/WELL_STAT_FAILURE_LIST.gql";

export default {
  components: { mylist: () => import("./StatList.vue") },
  props: {
    type: { type: String, default: "bar" },
    data: Array,
    headers: Array,
    colors: Array,
    title: { type: String, default: "" },
    list: Array,
    list_type: String,
  },
  data() {
    return {
      fluids: [],
      fluids_elements: [],

      testtype: 0,
      isTest: "0",
      title_list: "",
      header_list: [],
      items: [],
      lkey: 0,
      dialog: false,
      chartOptions: {
        title: {
          text: this.title,
        },
        tooltip: {
          trigger: "axis",
        },
        axisTick: {
          alignWithLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.headers,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 45,
          },
        },

        yAxis: {
          type: "value",
        },
        grid: {
          bottom: 100,
        },

        series: [
          {
            data: this.data,
            type: this.type,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(220, 220, 220, 0.8)",
            },

            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
          },
        ],
      },

      header_well: [
        {
          text: "Name",
          value: "wellname",
          selected: true,
        },
        {
          text: "Integrity",
          value: "integrity_status_name",
          selected: true,
        },
        {
          text: "lifecycle",
          value: "lifecyclename",
          selected: true,
        },
        {
          text: "compliant",
          value: "compliant_chk",
          selected: true,
          type: "check-box",
          slot: "checkbox",
        },
        {
          text: "Oil flow Rate (bpd)",
          value: "oilflow_rate",
          slot: "cur",
          align: "end",
          total: "oilflow_rate",
          selected: true,
          sortable: false,
        },
        {
          text: "Gas Flow Rate (cf/d)",
          value: "gasflow_rate",
          slot: "cur",
          align: "end",
          total: "gasflow_rate",
          selected: true,
          sortable: false,
        },
        {
          text: "Water Flow Rate (bwpd)",
          value: "waterflow_rate",
          slot: "cur",
          align: "end",
          total: "waterflow_rate",
          selected: true,
          sortable: false,
        },
        {
          text: "",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-view-list",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
      header_test: [
        {
          text: "Code",
          value: "code",
          selected: true,
        },
        {
          text: "Name",
          value: "wellname",
          selected: true,
        },

        {
          text: "Tested by",
          value: "agent",
          selected: true,
        },
        {
          text: "Tested at",
          value: "test_date",
          selected: true,
        },
        {
          text: "Status",
          value: "status",
          selected: true,
        },
        {
          text: "",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-view-list",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
      header_failure: [
        {
          text: "Name",
          value: "wellname",
          selected: true,
        },
        {
          text: "Component",
          value: "component",
          selected: true,
        },
        {
          text: "Failure",
          value: "failure",
          selected: true,
        },
        {
          text: "Taux",
          value: "taux",
          selected: true,
          slot: "chip",
          color: "color",
        },
        {
          text: "",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-view-list",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    async click_chart(params) {
      this.isTest = "0";
      if (this.list) {
        this.title_list = params.data.name + " Wells";
        this.header_list = this.header_well;
        if (this.list_type == "3") {
          this.items = this.list.filter(
            (x) => x.welltype_id == params.data.type
          );
        } else if (this.list_type == "4")
          this.items = this.list.filter((x) => x.lifecycle == params.data.type);
        this.lkey++;
        this.dialog = true;
      } else {
        if (this.list_type == "5") {
          this.title_list = params.data.name + " Tests";
          this.get_tests(params.data.type);
        } else if (this.list_type == "6") {
          this.title_list = params.data.name + " Failures";
          await this.get_failures(params.data.type);
        }
      }
    },
    close_dialog() {
      this.dialog = false;
    },
    async get_failures(type) {
      let r = await this.$requette(FailureList, {
        Fields: this.$store.state.fields,
        risk: type,
      });
      if (r.ok) {
        this.header_list = this.header_failure;
        this.items = r.data.active_failures_list;

        this.lkey++;
        this.dialog = true;
      }
    },
    async get_tests(type) {
      this.isTest = "1";
      this.header_list = this.header_test;
      this.testtype = parseInt(type);
      if (parseInt(type) == 10) {
        let r = await this.$requette(TestList, {
          Fields: this.$store.state.fields,
          Testtype_id: type,
        });
        if (r.ok) {
          this.items = r.data.active_test_list;
          this.fluids = r.data.fluids;
          this.fluids_elements = r.data.elements_list;
          this.lkey++;
          this.dialog = true;
        }
      } else {
        this.lkey++;
        this.dialog = true;
      }
    },
  },
};
</script>
